import React, { useState } from 'react'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby'


import "./Breadcrumb.css";


function Breadcrumb(props) {
    const data = props;
    console.log(data)
    return (
        <div className="breadcrumb-container relative">
            <div style={{ display: "grid" }}>
                    <StaticImage
                        style={{
                            gridArea: "1/1",
                            maxHeight: "240px"
                        }}
                        alt={"smile-child"}
                        loading={"eager"}
                        src={"./../../images/smile-child.jpg"}
                        formats={["auto", "webp", "avif"]}
                    />

                <div
                    style={{
                        gridArea: "1/1",
                        position: "relative",
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    {/* Any content here will be centered in the component */}
                    <div className="bread-hh container m-auto py-6 flex flex-wrap items-center px-4">
 
                    </div>
                </div>
            </div>


        </div>
    );
}

export default Breadcrumb;