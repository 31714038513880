import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import "./AboutUs.css";
import "./../Home/Contribution.css";

function AboutUs(props) {
    const data = props;

    return (
        <div className="about-container">

            <div className="py-16 md:py-16 m-auto container-cs text-center px-4 md:px-0">
                <h2 className="dk-title">
                    <span>{data.sectionName}</span>
                    <span>{data.title}</span>
                </h2>
                <p className="lead py-2">{data.paragraph}</p>
                <div className="flex flex-wrap justify-center">
                    {(data.subParagraph && data.subParagraph.length > 0) && data.subParagraph.map((item, i) => (
                        <div key={`contribution-${i}`} className="w-full md:w-1/2 contr-points pt-6 md:pt-4">
                            <div>
                                <GatsbyImage
                                    image={item.icon.localFile.childImageSharp.gatsbyImageData}
                                    alt={item.icon.altText}
                                    className=" m-auto"
                                />
                            </div>

                            <div dangerouslySetInnerHTML={{ __html: item.description }} />

                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AboutUs;